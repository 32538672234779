import React, {createContext, useSyncExternalStore} from 'react';

import {configHook} from '@pexip/config-manager/configHook';

import type {Config} from '../config';
import {config} from '../config';
import {useAssertedContext} from '../hooks/useAssertedContext';
import type {GetState, Subscribe} from '../types';

export const ConfigContext = createContext<typeof config | undefined>(
    undefined,
);

export const ConfigProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

export const useConfig = <T extends keyof Config>(key: T) =>
    configHook(useAssertedContext(ConfigContext))(key);

export const useAudioInputMuted = (
    getStatus: GetState<Config, 'audioInputMuted'>,
    subscribe: Subscribe = onChange =>
        config.subscribe('audioInputMuted', onChange),
) => useSyncExternalStore(subscribe, getStatus);

export const useVideoInputMuted = (
    getStatus: GetState<Config, 'videoInputMuted'>,
    subscribe: Subscribe = onChange =>
        config.subscribe('videoInputMuted', onChange),
) => useSyncExternalStore(subscribe, getStatus);
