import type {Signal, SignalVariant} from '@pexip/signal';
import {createSignal} from '@pexip/signal';

import type {SocketSignals} from './types';

/**
 *  indicates whether the given close code in the error range
 *
 * @param WebSocket-internal error code
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
 */
export const isWebSocketClosedWithError = (errorCode: number) =>
    errorCode > 1000 && errorCode < 1017;

export const isOpen = (readyState?: number) => readyState === WebSocket.OPEN;
export const isClosed = (readyState?: number) =>
    readyState === WebSocket.CLOSED;

export const isConnecting = (readyState?: number) =>
    readyState === WebSocket.CONNECTING;

/**
 * Create a general signal with consistent scoped name
 *
 * @param name - Signal name
 * @param scope - The scope of the signal for better logging
 * @param variant - The variant of the signal @see Signal @defaultValue 'generic'
 */
const createSocketSignal = <T = undefined>(
    name: string,
    scope = '',
    // FIXME: need `schedule` function to support `batched` variant
    variant: Exclude<SignalVariant, 'batched'> = 'generic',
) =>
    createSignal<T>({
        name: `socket-manager/${scope ? `${scope}/` : ''}${name}`,
        allowEmittingWithoutObserver: allowEmittingWithoutObserver(name),
        variant,
    });

const allowEmittingWithoutObserver = (signal: string) =>
    [
        'onConnecting',
        'onConnected',
        'onReconnecting',
        'onReconnected',
        'onDisconnected',
        'onError',
        'onMessage',
    ].includes(signal);

const REQUIRED_SOCKET_SIGNAL_KEYS = [
    'onConnecting',
    'onConnected',
    'onReconnecting',
    'onReconnected',
    'onDisconnected',
    'onError',
    'onMessage',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for call to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `SocketSignalsOptional`, @see SocketSignalsOptional
 *
 * The following signals created by default
 * - 'onRemoteStream',
 *
 * @see REQUIRED_SOCKET_SIGNAL_KEYS
 */
export const createSocketSignals = <T extends {type: string}>(scope = '') => {
    type SignalKeys = (typeof REQUIRED_SOCKET_SIGNAL_KEYS)[number];
    return REQUIRED_SOCKET_SIGNAL_KEYS.reduce(
        (signals, key) => ({
            ...signals,
            [key]: createSocketSignal<
                SocketSignals<T>[typeof key] extends Signal<infer S> ? S : never
            >(key, scope),
        }),
        {} as Pick<Required<SocketSignals<T>>, SignalKeys>,
    );
};
